exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-business-js": () => import("./../../../src/pages/business.js" /* webpackChunkName: "component---src-pages-business-js" */),
  "component---src-pages-data-analyst-data-analyst-jobs-js": () => import("./../../../src/pages/data-analyst/data-analyst-jobs.js" /* webpackChunkName: "component---src-pages-data-analyst-data-analyst-jobs-js" */),
  "component---src-pages-datascience-js": () => import("./../../../src/pages/datascience.js" /* webpackChunkName: "component---src-pages-datascience-js" */),
  "component---src-pages-design-js": () => import("./../../../src/pages/design.js" /* webpackChunkName: "component---src-pages-design-js" */),
  "component---src-pages-development-js": () => import("./../../../src/pages/development.js" /* webpackChunkName: "component---src-pages-development-js" */),
  "component---src-pages-experience-js": () => import("./../../../src/pages/experience.js" /* webpackChunkName: "component---src-pages-experience-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-journey-js": () => import("./../../../src/pages/journey.js" /* webpackChunkName: "component---src-pages-journey-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-pages-thoughts-index-js": () => import("./../../../src/pages/thoughts/index.js" /* webpackChunkName: "component---src-pages-thoughts-index-js" */),
  "component---src-pages-thoughts-mdx-slug-js": () => import("./../../../src/pages/thoughts/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-thoughts-mdx-slug-js" */)
}

